/* SASS variables */

/* Path */
$theme-path: '/assets/design2016';
$assets-path: '/assets';
//$cdn-path: '//cdn.thelundingroup.com/assets';

/* Font stacks */
$default-fontstack: 'source-sans-pro',sans-serif;
$brand-fontstack: 'source-sans-pro',sans-serif;
$tagline-fontstack: 'source-sans-pro',sans-serif;
$header-fontstack: 'source-sans-pro',sans-serif;
$nav-fontstack: 'source-sans-pro',sans-serif;

/*globerthin_free*/

/* Font sizes */
$default-fontsize: 20px;
$default-fontweight: 300;
$default-lineheight: 1.5em;
$nav-fontsize: 20px;
$nav-fontsize-480: 16px;
$nav-fontsize-768: 14px;
$nav-fontsize-980: 16px;
$footer-fontsize: 12px;
$tagline-fontsize: 30px;
$tagline-fontsize-768: 27px;
$tagline-fontsize-480: 17px;
$tagline-lineheight: 64px;
$tagline-lineheight-768: 54px;
$tagline-lineheight-480: 44px;
$tagline-fontweight: 100;
$block-summary-fontsize: 20px;
$block-summary-line-height: 30px;

/* Site-specific colour palette */
$default-fontcolor: #000;
$color-primary: #1770aa; // lighter-blue from design
$color-secondary: #0068a6; // dark blue from logo
$color-tertiary: #d4d4d4;  //grey from design
$nav-color: $color-secondary;
$color-black: #000000;
$color-grey: #9f9f9f;
$color-darkgrey: #515151;
$color-lightgrey: #f2f2f2;
$color-blue: #5c7589;
$color-gold: #a99970;
$color-yellow: #ffcc00;
$color-white: #ffffff;
$color-navbar-bg: $color-white;
$color-header-bg: #c4bd97;
$color-hover: #bfa72a;
$color-active: #bfa72a;
$color-link: $color-secondary;
$color-bodybg: #fff;

/* Additional colour palette */
$color-transblack: rgba(0,0,0,0.6);
$color-footer-bg: $color-tertiary;
$color-footer-text: #fff;
$color-tagline-bg: #CD712A;
$color-button-primary: #ce3734;
$color-breadcrumbs-bg: #F2F2F2;
$color-dropdown: $color-primary;

/* Width and height settings */
$headernav-height: 40px;
$headerlogo-height: 79px;
$navbar-height: 43px;
$subnav-height: 35px;
$subnav-extra-height: 55px;
$header-height: 160px;
$header-height-1220: 100px;
$header-height-980: 100px;
$header-height-768: 90px;
$header-height-480: 85px;
$header-height-320: 80px;
$header-img-height: 205px;
$page-title-height: 105px;
$home-title-height: 150px;
$home-feature-height: 196px;
$home-slider-height: 420px;
$home-slider-height-320: 420px;
$sub-slider-height: 180px;
$footer-height: 50px;
$footer-features-height: 195px;
$nav-padding: 10px;
$breadcrumbs-height: 40px;

/* Breakpoints */
$mini: 320px;
$small: 480px;
$medium: 768px;
$large: 960px;
$xlarge: 1220px;

/* SASS Mixins */

/* Vertical align center everything */
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin preserve-3d {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

  /* Text shadow */
  @mixin textshadow($offset-y,$offset-x,$blur,$color,$opacity){
    text-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  }

  /* Opacity and colour */
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

@mixin box-shadow($offset-y,$offset-x,$blur,$color,$opacity) {
  -webkit-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  -moz-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  background-clip: padding-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

// Single side border-radius
@mixin border-topleft-radius($radius) {
   -webkit-border-top-left-radius: $radius;
   -moz-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-topright-radius($radius) {
   -webkit-border-top-right-radius: $radius;
   -moz-border-top-right-radius: $radius;
   border-top-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomright-radius($radius) {
   -webkit-border-bottom-right-radius: $radius;
   -moz-border-bottom-right-radius: $radius;
   border-bottom-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomleft-radius($radius) {
   -webkit-border-bottom-left-radius: $radius;
   -moz-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
