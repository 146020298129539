/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

/* links */
a {
    color: $color-primary;
}
.uk-active a,
a:hover {
    text-decoration: none;
    color: $color-secondary;
}

.adnet {
    color: #349AC0;
    font-size: 13px;
    margin-left:10px;
}

.adnet:hover {
    color: darken(#349AC0, 10%);
}

/* Typography */
html, body {
    line-height: $default-lineheight;
    font-size: $default-fontsize;
    font-weight: $default-fontweight;
     font-family: $default-fontstack;
    color: $default-fontcolor;
}

html, body {
    font-family: $default-fontstack;
}

small {
    font-size: 11px;
}

h1 {
    font-family: $tagline-fontstack;
    color: $color-black
}

h2, h3, h4, h5, h6 {
    font-family: $header-fontstack;
}

h2.header {
    margin: 20px 0;
    color: $color-primary;
}

#page-title {
    margin: 0 0 20px 0;
    font-size: 45px;
    line-height: 1.3em;
}

h2.header.header-large {
    font-size: 38px;
    line-height: 42px;
    font-weight: 600;
    margin: 60px 0 35px;
}

.default-font {
    font-family: $default-fontstack;
}

/* Colour stuff */
.color-primary {
    color: $color-primary !important;
}
.color-secondary {
    color: $color-secondary !important;
}
.color-tertiary {
    color: $color-tertiary !important;
}
.bg-primary {
    background-color: $color-primary !important;
}
.bg-secondary {
    background-color: $color-secondary !important;
}
.bg-tertiary {
    background-color: $color-tertiary !important;
}
.bg-white {
    background-color: $color-white !important;
}

/* Text headers */
h1, h2, h3, h4 {
    font-family: $header-fontstack;
    color: $color-primary;
}

/* Header */
header.header {
    width: 100%;
    height: $header-height;
    background: $color-white;
    border-bottom: 3px solid $color-primary;
}

#stock-quote {
    transition: 0.3s all ease;
    padding: 5px;
}

#stock-quote a{
    font-size: 23px;
}

#stock-quote a span {
    padding: 0px 10px;
}

#stock-quote:hover {
    background: $color-primary;
}

#stock-quote:hover a {
    color: $color-white;
}

h3.section-title {
    font-size: 24px;
}

/* Header logo */

/* Sticky nav */
header.header.sticky-nav {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    z-index: 2;
}

.logo {
    margin-top: 35px;
}

#page-hero.sticky-nav {
    margin-top: 0;
}

/* Main Nav */
.uk-navbar {
    background: none;
    color: $nav-color;
    border: none;
    border-radius: 0;
}

.uk-navbar-nav > li > a {
    color: $nav-color;
    text-shadow: none;
    font-size: $nav-fontsize;
    text-transform: uppercase;
    font-family: $nav-fontstack;
    font-weight: 300;
    border-right: 1px solid white;
}

.uk-navbar-nav > li.last > a {
    border-right:none;
}

.uk-navbar-nav > li.uk-open > a, .uk-navbar-nav > li:hover > a, .uk-navbar-nav > li > a:focus, .uk-navbar-nav > li.uk-active > a {
    background-color: $color-primary;
    color: $color-white;
}

.uk-navbar-flip .uk-navbar-nav:last-child > li:last-child > a {
    border-radius: 0;
}

/* dropdowns */
.uk-dropdown-navbar{
    margin-top:0px;
    background: $color-primary;
    color: $color-white;
}

.uk-dropdown {
    border: none;
    border-radius: 0;
}

.uk-nav-navbar > li > a{
    color: $color-white;
}

.uk-nav-navbar > li > a:focus, .uk-nav-navbar > li > a:hover {
    background: $color-tertiary;
    color: $color-primary;
}

/* mobile nav */

.mobile-nav {
    position: absolute;
    right: 15px;
    top: 20px;
}

.mobile-nav a {
    color: $color-white;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background: $color-white;
}

.hamburger.is-active {
    cursor: pointer;
}

.uk-offcanvas {
    cursor: pointer;
}

/* Side Nav */
.side-nav {
    border-right: 5px solid $color-primary
}

.side-nav li {
    padding-bottom: 15px;
    text-transform: uppercase;
}

.side-nav li.uk-active {
    font-weight: 700;
}

/* Sliders */

#slider {
    position: relative;
    z-index: 1;
    top: 0;
    overflow: hidden;
}

#slider,
#slider .slide {
    width: 100%;
    height: $sub-slider-height;
}

.home #slider,
.home #slider .slide {
    width: 100%;
    height: $home-slider-height;
}

.slider-img {
    background: url('#{$theme-path}/img/slider-banner.jpg') no-repeat 85% 16%;
    background-size: cover;
}

.home #slider {
    background: url('#{$theme-path}/img/slider-home-banner.jpg') no-repeat 85% 16%;
    background-size: cover;
}

.caption-bg {
    background-color: rgba($color-black, 0.7);
    color: $color-white;
    margin: 50px 20px;
    padding: 30px 40px
}

.caption-bg h1.header {
    color: $color-white;
    font-family: $tagline-fontstack;
    text-transform: uppercase;
}

.caption-bg h1.header .word5 {
    color: $color-hover;
}

.uk-dotnav>*>* {
    width: 15px;
    height: 15px;
    background: rgba($color-primary, 0.4);
}

.uk-dotnav>.uk-active>* {
    transform: none;
    background: $color-primary;
}

#slider .uk-dotnav.uk-position-bottom {
    bottom: 60px;
}

#slider .uk-dotnav>*>* {
    background: $color-primary;
    box-shadow: 0 0 10px rgba($color-black, 0.3)
}

#slider .uk-dotnav>.uk-active>* {
    background: $color-white;
}

#slider-nav span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: $color-white;
    margin: 0 3px;
    text-indent: -9999em;
    cursor: pointer
}

#slider-nav span:hover {
    background: $color-primary
}

#slider-nav span.cycle-pager-active {
    background: #003661;
    border: 1px solid #0e94db
}

.uk-slidenav {
    width: 45px;
    height: 140px;
    line-height: 140px;
}

.uk-slidenav-position .uk-slidenav {
    top: 40%;
    display: block;
}

.uk-slidenav-position .uk-slidenav-previous {
    left: 0;
}

.uk-slidenav-position .uk-slidenav-next {
    right: 0;
}

#slider .uk-slidenav {
    position: absolute;
    z-index: 200;
    margin: 0 0 100px 0;
}

#slider .uk-slidenav.uk-slidenav-previous {
    left: 0;
    top: 35%;
}

#slider .uk-slidenav.uk-slidenav-next {
    right: 0;
    top: 35%;
}

#sliderpager.uk-dotnav {
    margin-top: -55px;
    z-index: 200;
    position: relative;
}

#sliderpager.uk-dotnav>.cycle-pager-active>* {
    background: $color-white;
}

#caption-container {
}

.caption {
    background: $color-primary;
    color: $color-white;
    height: 95px;
}

.caption p {
    font-size: 38px;
    margin:0;
    line-height: 1.3em;
}


/* Home page layout */
#right-col h2 {
    color: $color-secondary;
    font-weight: 300;
    font-size: 21px;
    text-transform:uppercase;
}

#right-col .uk-icon-button {
    background: $color-black;
    color: $color-white;
}

.entry-date {
    display: inline-block;
    color: $color-primary;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}

.entry-title {
    display:block;
    color: $default-fontcolor;
    font-size: 14px;
    line-height: 1.3em;
}

#news-list .entry-date {
    font-size: 16px;
}

#news-list .entry-title {
    font-size: 20px;
}

#right-col #news-list .entry-title {
    font-size: 16px;
}


.entry-link {
    font-size: 14px;
    font-weight: 500;
}

#news-list  a span {
    transition: 0.4s ease all;
}

#news-list  a:hover .entry-title {
    color: $color-primary;
}


/* Home page features */
.uk-panel-box {
    background: #f2f2f2;
    border-radius: 0;
    border: none;
    border-top: 10px solid $color-primary;
    border-bottom: 5px solid $color-secondary;
    transition: 0.3s all ease;
}

#features .uk-panel {
    min-height: 170px;
}

#features .uk-panel-box {
    border: none;
    background:url('#{$theme-path}/img/features-bg.jpg') no-repeat center center fixed;
}

#features .uk-panel h3 {
    color: $color-white;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 300;
}

#features .uk-panel h3 span {
    font-weight: 700;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow:hidden;
}

#features .uk-panel h3 span::after {
    content: "";
    position: absolute;
    left:0;
    top: -15px;
    width: 90px;
 border-bottom: 8px solid #fff;
    transition: border-color 0.3s ease-out;
}

#features .uk-panel:hover h3 span::after  {
    border-bottom: 8px solid $color-primary;
}

.uk-panel-hover:hover {
    border: none;
    border-top: 10px solid $color-secondary;
    border-bottom: 5px solid $color-primary;
}

/* footer */
.footer-bg {
    background: $color-footer-bg;
    color: #005b90;
    font-size: 16px;
}

.footer-nav {
    margin: 0;
    width:100%
}

.footer-nav li {
    display: inline;
    float:left;
    list-style-type:none;
    color: #3e4448;
}

.footer-nav li a {
    color:#3e4448;
}



.footer-nav li a:hover, .footer-nav li.uk-active a{
    color: $color-primary;
}

.footer-nav li + li {
    margin-left: 35px;
}


/* Stock Chart */
.stock-data .price {
    font-size: 48px;
    padding-bottom: 20px;
}

.stock-data .change {
    font-size: 20px;
}

.stock-data .uk-icon {
    margin-top: 0;
}

/* bios */
.name {
    margin-bottom: 0px;
}
.position {
    margin:0;
}

/* Pages */
.overview {
    font-size: 24px;
    color: $color-primary;
}

/* PResentations */
.presentation {
  font-family: 'Quattrocento', Arial, sans-serif;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #141414;
  text-align: left;
  line-height: 1.4em;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid $color-tertiary;
}
.presentation * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.presentation img {
  max-width: 100%;
  vertical-align: top;
}
.presentation figcaption {
  width: 100%;
  background-color: #ffffff;
  padding: 10px 25px 55px;
  position: relative;
}
.presentation figcaption:before {
  position: absolute;
  content: '';
  z-index: 2;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 80px;
  background-image: -webkit-linear-gradient(top, transparent 0%, #ffffff 100%);
  background-image: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
}
.presentation figcaption a {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: $color-primary;
  color: $color-white;
  font-size: 0.8em;
  text-transform: uppercase;
  opacity: 0.65;
  width: 100%;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
}
.presentation figcaption a:hover {
  opacity: 1;
}
.presentation h2 {
  margin: 0 0 10px;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.2em;
  font-family: 'Oswald', Arial, sans-serif;
  text-transform: uppercase;
}
.presentation p {
  margin: 0 0 10px;
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

/* Parsley overrides */
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
    color: #468847;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error{
    color: #B94A48;
    background-color: #F2DEDE;
    border: 1px solid #EED3D7;
}

.parsley-errors-list {
    margin: 2px 0 3px;
    padding: 0;
    color: #c00;
    list-style-type: none;
    font-size: 0.9em;
    line-height: 0.9em;
    opacity: 0;
    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
    opacity: 1;
}

.footer-features .parsley-errors-list {
    position: absolute;
    left:0;bottom:-25px;
}
#contact .parsley-errors-list {
    margin-top: 5px;
}


/* uikit overrides */
em {
    color: inherit
}

.uk-button {
    border-radius: 0;
}

.uk-button-primary {
    background: $color-primary;
}

.uk-button-primary:hover {
    background: $color-primary;
}

.uk-icon {
    margin-top: 5px;
}

.uk-table thead th, .uk-table thead td, .news-section .uk-table tr:first-child td {
    background: $color-primary;
    color: $color-white;
    text-align: center;
}

.uk-table td p {
    padding: 0;
    margin:0;
}

h3.uk-panel-title {
    font-size: 22px;
    color: $color-primary;
}

/* Hover.css overrides */
.hvr-icon-float-away:before {
    content: '\f1c1';
}

.hvr-icon-float-away:after, .hvr-icon-float-away:before {
    content: '\f1c1';
}

.hvr-icon-grow:before {
    content: '\f1c1';
}

.html.hvr-icon-grow:before {
    content: '\f0f6';
}

/* Map */

.map {
    width: 100%;
    height: 300px;
}

.map img {
    max-width: none
}


/* Misc */
.iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Media queries */
@media (min-width: 320px) and (max-width: 479px) {

	.rwd-br {
        display: block;
    }

   .home #slider,
   .home #slider .slide {
        height: $home-slider-height-320;
    }

    #caption-container {
        bottom: 0px;
        padding-right:0;
    }

    .caption p {
        font-size:20px;
    }

    .uk-dotnav {
        display:none;
    }

    .logo {
        display:block;
        margin-top: 0px;
    }

     #stock-quote  {
        margin-top: 15px;
    }

      .footer-nav li + li {
        margin-left: 15px;
    }

    .mobile-nav {
        top: 90px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
	.rwd-br {
        display: block;
    }

     #caption-container {
        bottom: 0px;
        padding-right:0;
    }

    .caption p {
        font-size:28px;
    }

     .uk-dotnav {
        display:none;
    }

    .logo {
        margin-top: 0px;
    }

    .footer-nav li + li {
        margin-left: 10px;
    }

    .uk-navbar-nav > li > a {
        font-size: $nav-fontsize-480;
         padding: 0 8px;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
	.rwd-br {
        display: block;
    }

    .logo {
        margin-top:45px;
    }

    #stock-quote a {
        font-size: 12px;
    }

    .footer-nav li + li {
        margin-left: 15px;
    }

     .uk-navbar-nav > li > a {
        font-size: $nav-fontsize-768;
        padding: 0 6px;
    }
}

@media (min-width: 980px) and (max-width: 1219px) {
	.rwd-br {
        display: inline-block;
    }

    .logo {
        margin-top:45px;
    }

    .uk-navbar-nav > li > a {
        font-size: $nav-fontsize-980;
        padding: 0 10px;
    }
}

@media (min-width: 1220px) {
	.rwd-br {
        display: inline-block;
    }
}
